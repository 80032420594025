<template>
	<div class="commonlayout">
		<div class="content">
			<slot></slot>
		</div>
		<page-footer></page-footer>
	</div>
</template>

<script>
	import PageFooter from "@/components/PageFooter";

	export default {
		name: "CommonLayout",
		components: { PageFooter }
	};
</script>

<style scoped lang="scss">
	.commonlayout {
		display: flex;
		flex-direction: column;
		height: 100vh;
		overflow: auto;
		background-color: #f0f2f5;
		background-image: url("https://gw.alipayobjects.com/zos/rmsportal/TVYTbAXWheQpRcWDaDMu.svg");
		background-repeat: no-repeat;
		background-position-x: center;
		background-position-y: 110px;
		background-size: 100%;
		.content {
			flex: 1;
		}
	}
</style>
