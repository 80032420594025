<template>
	<common-layout>
		<div class="login">
			<h1>轻舟能科综合管理后台</h1>
			<a-form @submit="onSubmit" :form="form">
				<a-form-item>
					<a-input
						autocomplete="autocomplete"
						size="large"
						placeholder="请输入账户名"
						v-decorator="[
							'username',
							{
								rules: [
									{
										required: true,
										message: '请输入账户名',
										whitespace: true
									}
								]
							}
						]"
					>
						<a-icon slot="prefix" type="user" />
					</a-input>
				</a-form-item>
				<a-form-item>
					<a-input
						size="large"
						placeholder="请输入密码"
						autocomplete="autocomplete"
						type="password"
						v-decorator="[
							'password',
							{
								rules: [
									{
										required: true,
										message: '请输入密码',
										whitespace: true
									}
								]
							}
						]"
					>
						<a-icon slot="prefix" type="lock" />
					</a-input>
				</a-form-item>
				<a-form-item>
					<a-button
						:loading="loading"
						style="width: 100%; margin-top: 24px"
						size="large"
						htmlType="submit"
						type="primary"
					>
						登录
					</a-button>
				</a-form-item>
			</a-form>
		</div>
	</common-layout>
</template>

<script>
	import CommonLayout from "@/layouts/CommonLayout";
	import { login, userAuth } from "@/api/index";
	
	export default {
		name: "LoginPage",
		components: { CommonLayout },
		data() {
			return {
				loading: false,
				form: this.$form.createForm(this)
			};
		},
		methods: {
			onSubmit(e) {
				e.preventDefault();
				this.login();
			},

			login() {
				const {
					form: { validateFields }
				} = this;
				console.log(111)
				validateFields(["username", "password"], (err, values) => {
					if (!err) {
						this.loading = true;
						login({
								userName: values.username,
								password: values.password
							})
							.then(res => {
								if (res.code == 200) {
									localStorage.setItem("_userInfo", JSON.stringify(res.data));
									localStorage.setItem("token",res.data.token);
									
									this.$store.commit("setState", {
										_userInfo: res.data
									});
									
									console.log(res.data);
									//this.$router.push({ name: "overview" });
									this.getAuth();
								} else {
									this.loading = false;
								}
							}).catch(() => (this.loading = false));
					}else{
						this.$message.error("登录名或密码不能为空");
					}
				});
			},
			// 获取角色权限
			getAuth() {
				userAuth()
					.then(res => {
						if (res.code !== 200) {
							this.$message.info("该用户没有任何权限，无法登录");
							return;
						}
						this.routerDFS(res.data);
					})
					.finally(() => (this.loading = false));
			},
			// 权限跳转
			routerDFS(arr) {
				let result = [];
				let temp = JSON.parse(JSON.stringify(arr));
				while (temp.length) {
					let item = temp.shift();
					if (item.childList && item.childList.length) {
						temp.unshift(...item.childList);
					} else {
						item.parentId && result.push(item);   //一级菜单不添加（前台、后台）
					}
				}
				if (!result.length) {
					this.$message.info("没有访问权限");
					return;
				}
				localStorage.setItem("_userAuth", JSON.stringify(result));
				this.$router.push({ name: result[0].type });
			}
		}
	};
</script>

<style lang="scss">
	.content {
		position: relative;
	}
	.login {
		position: absolute;
		top: 50%;
		left: 50%;
		transform: translate(-50%, -50%);
		width: 368px;
		h1 {
			text-align: center;
		}
	}
</style>
