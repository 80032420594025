<template>
	<div class="pagefooter">Copyright © 2022</div>
</template>

<script>
	export default {
		name: "PageFooter",
		props: ["copyright"]
	};
</script>

<style lang="scss" scoped>
	.pagefooter {
		padding: 48px 16px 24px;
		text-align: center;
	}
</style>
