var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('common-layout',[_c('div',{staticClass:"login"},[_c('h1',[_vm._v("轻舟能科综合管理后台")]),_c('a-form',{attrs:{"form":_vm.form},on:{"submit":_vm.onSubmit}},[_c('a-form-item',[_c('a-input',{directives:[{name:"decorator",rawName:"v-decorator",value:([
						'username',
						{
							rules: [
								{
									required: true,
									message: '请输入账户名',
									whitespace: true
								}
							]
						}
					]),expression:"[\n\t\t\t\t\t\t'username',\n\t\t\t\t\t\t{\n\t\t\t\t\t\t\trules: [\n\t\t\t\t\t\t\t\t{\n\t\t\t\t\t\t\t\t\trequired: true,\n\t\t\t\t\t\t\t\t\tmessage: '请输入账户名',\n\t\t\t\t\t\t\t\t\twhitespace: true\n\t\t\t\t\t\t\t\t}\n\t\t\t\t\t\t\t]\n\t\t\t\t\t\t}\n\t\t\t\t\t]"}],attrs:{"autocomplete":"autocomplete","size":"large","placeholder":"请输入账户名"}},[_c('a-icon',{attrs:{"slot":"prefix","type":"user"},slot:"prefix"})],1)],1),_c('a-form-item',[_c('a-input',{directives:[{name:"decorator",rawName:"v-decorator",value:([
						'password',
						{
							rules: [
								{
									required: true,
									message: '请输入密码',
									whitespace: true
								}
							]
						}
					]),expression:"[\n\t\t\t\t\t\t'password',\n\t\t\t\t\t\t{\n\t\t\t\t\t\t\trules: [\n\t\t\t\t\t\t\t\t{\n\t\t\t\t\t\t\t\t\trequired: true,\n\t\t\t\t\t\t\t\t\tmessage: '请输入密码',\n\t\t\t\t\t\t\t\t\twhitespace: true\n\t\t\t\t\t\t\t\t}\n\t\t\t\t\t\t\t]\n\t\t\t\t\t\t}\n\t\t\t\t\t]"}],attrs:{"size":"large","placeholder":"请输入密码","autocomplete":"autocomplete","type":"password"}},[_c('a-icon',{attrs:{"slot":"prefix","type":"lock"},slot:"prefix"})],1)],1),_c('a-form-item',[_c('a-button',{staticStyle:{"width":"100%","margin-top":"24px"},attrs:{"loading":_vm.loading,"size":"large","htmlType":"submit","type":"primary"}},[_vm._v(" 登录 ")])],1)],1)],1)])}
var staticRenderFns = []

export { render, staticRenderFns }